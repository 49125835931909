import { makeAutoObservable, reaction, runInAction } from "mobx";
import { Store } from "./Store";

import { navigate } from "@reach/router";
import { ApiClient, GraphqlClient } from "~libs/api";
import { isBrowser } from "~libs/utils";
import { USER_LOGIN_EMAIL_FIELD_ID } from "~views/shared/modals/UserLoginModal";

export class LoginStore {
  loginModalOpen: boolean = false;

  userEmail: string = "";

  userEmailInvalid: boolean = true;

  userEmailErrorMessage: string = "";

  userPassword: string = "";

  userPasswordInvalid: boolean = true;

  userPasswordErrorMessage: string = "";

  requestStatusMessage: string = "";

  loginRequestStatusType: "error" | "success" | "pending" | "idle" = "idle";

  redirectSlug: string = "";

  isSCEmail: boolean | undefined = undefined;

  private timer: null | ReturnType<typeof setInterval> = null;

  private emailInput: HTMLElement | null = null;

  private isAutofilled: boolean | undefined = undefined;

  constructor(private rootStore: Store, private graphqlClient: GraphqlClient, private unauthenticatedClient: ApiClient) {
    makeAutoObservable(this);
    reaction(
      () => this.loginModalOpen,
      open => {
        this.resetRequestStatusMessage();
        if (open) {
          this.rootStore.sectionsContainerStore.setScrollPos();

          /**
           * Check if the form has been autofilled.
           * - Interval is used to poll until form has rendered completely
           * - Once we know if input is autofilled or not, we clear the interval
           */
          if (!this.emailInput && typeof this.isAutofilled === "undefined") {
            this.timer = setInterval(() => {
              if (!this.emailInput) {
                this.emailInput = document.getElementById(USER_LOGIN_EMAIL_FIELD_ID);
              } else {
                this.isAutofilled = this.emailInput.matches(":autofill");
              }
            }, 500);
          }
        } else {
          this.rootStore.sectionsContainerStore.resetScrollPos();

          if (this.timer) {
            clearInterval(this.timer);
          }

          this.emailInput = null;
        }
      },
      {
        fireImmediately: true
      }
    );
    reaction(
      () => this.isAutofilled,
      () => {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.validateUserEmail();
        this.validateUserPassword();
      }
    );
    reaction(
      () => this.userEmail,
      () => {
        this.validateUserEmail();
      }
    );
    reaction(
      () => this.userPassword,
      () => {
        this.validateUserPassword();
      }
    );
  }

  get formIsValid() {
    return !this.userEmailInvalid && !this.userPasswordInvalid && typeof this.isSCEmail !== "undefined" && !this.isSCEmail;
  }

  /**
   * Empty login form
   */
  emptyLoginForm = () => {
    this.userEmail = "";
    this.userPassword = "";
  };

  /**
   * Set prettier error messages for errors recieved from the login request.
   * @param  {string} errorMessage [The error message recieved from graphql.]
   */
  setLoginErrorMessage = (errorMessage: string) => {
    this.loginRequestStatusType = "error";
    if (errorMessage?.includes("incorrect_password")) {
      this.requestStatusMessage = "Wrong password.";
    } else if (errorMessage?.includes("invalid_email")) {
      this.requestStatusMessage = "Invalid email.";
    }
  };

  /**
   * Set login request status message to idle.
   */
  resetRequestStatusMessage = () => {
    this.loginRequestStatusType = "idle";
  };

  /**
   * Handle the user login to the wordpress backend.
   */
  login = async () => {
    const { userStore, authenticationStore } = this.rootStore;

    if (this.loginRequestStatusType === "pending") return;

    this.loginRequestStatusType = "pending";

    const response = await this.graphqlClient.login(this.userEmail, this.userPassword);

    runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();

        runInAction(() => {
          if (errors) {
            const { message } = errors?.[0];
            this.setLoginErrorMessage(message);
          } else {
            const user = data?.login?.user;
            if (user) {
              authenticationStore.setAccessToken(user.jwtAuthToken, user.jwtRefreshToken, user.jwtAuthExpiration);
              userStore.setCurrentUser(user);
              if (this.redirectSlug) navigate(this.redirectSlug, { state: { reload: true }, replace: true }); // HACK!
            }
            this.emptyLoginForm();
            this.closeLoginModal();
            this.resetRequestStatusMessage();
          }
        });
      } else {
        this.loginRequestStatusType = "idle";
        console.error(response);
      }
    });
  };

  /**
   * Handle the user log out from wordpress backend.
   */
  handleLogout = async () => {
    const { authenticationStore, userStore, languageStore } = this.rootStore;
    const { currentLanguage } = languageStore;

    const homepageUrl = "/" + (currentLanguage?.slug !== "en" ? `${currentLanguage?.slug}/` : "");

    if (isBrowser() && !window.localStorage.getItem("access_token") && location.pathname !== homepageUrl) {
      await navigate(homepageUrl);
    }

    authenticationStore.removeAccessTokens();
    userStore.removeCurrentUser();
    this.resetRequestStatusMessage();
  };

  /**
   * Send a user account recovery email from worpress backend.
   * Setting appropriate request status message depending on the request result.
   * Sending the recovery email to the email adress set in the input field of the login modal.
   */
  sendPasswordResetEmail = async () => {
    const response = await this.graphqlClient.resetPassword(this.userEmail);

    runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();
        runInAction(() => {
          if (errors) {
            const { message } = errors?.[0];
            this.loginRequestStatusType = "error";
            this.requestStatusMessage = message.substr(0, message.lastIndexOf("."));
            setTimeout(() => {
              this.requestStatusMessage = "";
            }, 2500);
          } else {
            this.loginRequestStatusType = "success";
            this.requestStatusMessage = "Recovery email sent.";
            setTimeout(() => {
              this.requestStatusMessage = "";
            }, 2500);
          }
        });
      } else {
        console.error(response);
      }
    });
  };

  /**
   * Open the user login modal.
   */
  openLoginModal = (slug?: string) => {
    if (slug) this.redirectSlug = slug;
    this.loginModalOpen = true;
    if (isBrowser()) window.localStorage.setItem("redirect_to", slug);
  };

  /**
   * Close the user login modal.
   */
  closeLoginModal = () => {
    this.loginModalOpen = false;
  };

  /**
   * Set the user email variable. If the input is an empty string the validation will be run.
   * @param  {string} email [The email string you want the email variable to be set to.]
   */
  setUserEmail = (email: string) => {
    this.userEmail = email;
  };

  /**
   * Validate the user email and set the user email error message variable if the email is invalid.
   */
  validateUserEmail = async () => {
    const invalid = !this.rootStore.validationStore.validateEmail(this.userEmail);

    if (invalid) {
      this.userEmailErrorMessage = this.rootStore.validationStore.getEmailErrorMessage();
    } else {
      this.checkSCEmailDomain();
    }

    this.userEmailInvalid = invalid;
  };

  /**
   * Validate and set the user password.
   * @param  {string} password [The password string you want the password to be set to.]
   */
  setUserPassword = (password: string) => {
    this.userPassword = password;
  };

  /**
   * Validate the user password and set the user password error message variable if the password is invalid.
   */
  validateUserPassword = () => {
    this.userPasswordInvalid = !this.userPassword.length;

    if (this.userPasswordInvalid) {
      this.userPasswordErrorMessage = this.rootStore.validationStore.getPasswordErrorMessage(this.userPassword);
    }
  };

  SSOLogin = () => {
    navigate(`${process.env.GATSBY_WP_BASE_URL}/?option=saml_user_login`);
  };

  checkSCEmailDomain = () => {
    this.unauthenticatedClient
      .getIsEmailSCDomain(this.userEmail)
      .then(response => {
        this.isSCEmail = !!response.data;
      })
      .catch(_ => { });
  };
}
